import { Outlet } from 'react-router-dom';
import { Footer } from './components/Footer/Footer';
import { Nav } from './components/Nav/Nav';
import { NextNotice } from './components/NextNotice/NextNotice';

export const App = () => {
  return (
    <>
      <div className="min-h-screen">
        <NextNotice />
        <Nav />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
