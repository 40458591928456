import { Link } from 'react-router-dom';

const paradiseScreenshot = new URL('../assets/paradise-pro-display-xdr.png', import.meta.url);

export const Home = () => {
  return (
    <main className="">
      <section className="max-w-screen-2xl mx-auto py-24 text-center">
        <div className="font-semibold mb-8 text-festival-500 text-2xl">Unchanged but still new</div>
        <div className="font-bold text-8xl">Welcome to Team Festival</div>
      </section>

      <section className="my-32">
        <div className="grid grid-cols-2 max-w-screen-2xl mx-auto">
          <main className="self-center">
            <div className="font-bold text-5xl">Paradise</div>
            <div className="leading-relaxed max-w-md my-6 text-xl">Play UberStrike as it was meant to be, and relive the memories of the past once again.</div>
            <Link className="font-semibold text-festival-500" to="/projects/paradise">
              Discover the project
            </Link>
          </main>

          <img src={paradiseScreenshot.toString()} />
        </div>
      </section>
    </main>
  );
};
