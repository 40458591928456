import { Link, NavLink } from 'react-router-dom';

const wordmark = new URL('../../assets/wordmark.svg', import.meta.url);

export const Nav = () => {
  return (
    <nav className="flex flex-wrap font-semibold gap-4 justify-center md:justify-between p-6 lg:p-12 text-lg">
      <Link className="flex-shrink-0 w-full md:w-auto" to="/">
        <img className="h-6 mx-auto" src={wordmark.toString()} />
      </Link>

      <div className="flex items-center space-x-4 lg:space-x-10">
        <NavLink to="/about">About</NavLink>
        <NavLink to="/projects">Projects</NavLink>
      </div>
    </nav>
  );
};
