import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../Logo/Logo';
import './Footer.scss';

export const FooterLink = ({ children, to }: { children: ReactNode; to: string }) => {
  return (
    <Link className="footer-link relative hover:text-festival-500" to={to}>
      {children}
    </Link>
  );
};

export const Footer = () => {
  return (
    <footer className="px-6 lg:px-12">
      <section className="max-w-screen-2xl mx-auto py-10 lg:py-20 lg:text-lg">
        <div className="flex flex-col gap-x-16 grid-cols-3 lg:inline-grid space-y-8 lg:space-y-0">
          <ul className="space-y-4 lg:space-y-6">
            <div className="text-zinc-500">Explore the Festival</div>
            <li>
              <FooterLink to="/about">About</FooterLink>
            </li>
            <li>
              <FooterLink to="/lineup">Lineup</FooterLink>
            </li>
            <li>
              <a className="footer-link relative hover:text-festival-500" href="https://blog.festival.tf/">
                Stories
              </a>
            </li>
          </ul>

          <ul className="space-y-4 lg:space-y-6">
            <div className="text-zinc-500">Projects</div>
            <li>
              <FooterLink to="/projects/paradise">Paradise</FooterLink>
            </li>
            <li>
              <FooterLink to="/projects/unicode">UNIcode</FooterLink>
            </li>
            <li>
              <FooterLink to="/projects/valhalla">Valhalla</FooterLink>
            </li>
          </ul>

          <ul className="space-y-4 lg:space-y-6">
            <div className="text-zinc-500">Services</div>
            <li>
              <a className="footer-link relative hover:text-festival-500" href="https://arcade.festival.tf/">
                Arcade
              </a>
            </li>
            <li>
              <a className="footer-link relative hover:text-festival-500" href="https://repo.festival.tf/">
                Cydia Repo
              </a>
            </li>
            <li>
              <a className="footer-link relative hover:text-festival-500" href="https://account.festival.tf/">
                Manage Account
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section className="border-zinc-900 lg:border-t flex flex-col footer-grid lg:grid items-start lg:items-center max-w-screen-2xl mx-auto py-6 lg:py-10 text-sm">
        <div className="mb-6 lg:mb-0 text-zinc-500">Made with 🤍 in Hannover</div>

        <Logo className="lg:block hidden h-6 mx-auto text-zinc-500" />

        <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-8 space-y-2 lg:space-y-0 justify-end">
          <FooterLink to="/contact">Contact</FooterLink>
          <FooterLink to="/privacy">Privacy</FooterLink>
          <a className="footer-link relative hover:text-festival-500" href="https://backstage.festival.tf/">
            Backstage Area
          </a>

          <div className="lg:block hidden text-zinc-500">&copy; 2022</div>
        </div>

        <div className="flex lg:hidden items-center mt-6 space-x-4">
          <Logo className="h-6 text-zinc-500" />
          <div className="text-zinc-500">&copy; 2022</div>
        </div>
      </section>
    </footer>
  );
};
