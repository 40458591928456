import classNames from 'classnames';

export const Logo = ({ className }: { className?: string }) => {
  return (
    <svg className={classNames(className)} fill="none" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0H486.111C498.005 0 505.741 12.5171 500.422 23.1555L452.422 119.156C449.711 124.576 444.171 128 438.111 128H16C7.16346 128 0 120.837 0 112V16Z"
        fill="currentColor"
      />
      <path
        d="M0 208C0 199.163 7.16344 192 16 192H390.111C402.005 192 409.741 204.517 404.422 215.155L356.422 311.155C353.711 316.576 348.171 320 342.111 320H16C7.16346 320 0 312.837 0 304V208Z"
        fill="currentColor"
      />
      <path
        d="M0 400C0 391.163 7.16344 384 16 384H294.111C306.005 384 313.741 396.517 308.422 407.155L260.422 503.155C257.712 508.576 252.172 512 246.111 512H16C7.16344 512 0 504.837 0 496V400Z"
        fill="currentColor"
      />
    </svg>
  );
};
